import React, { useContext } from "react"
import {
  Seo,
  Header,
  LiveStream,
  DonateButton,
  LogoContainer,
  SocialPromo,
  Footer,
} from "../components"
import { Section, FlexContainer, H4, P, Small } from "../styles"
import { ThemeContext } from "../contexts"

const IndexPage = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <>
      <Seo pageSpecificTitle="Paddy V Stevo" />
      <Header />
      <LiveStream />
      <Section>
        <FlexContainer>
          <P $theme={theme}>
            The <strong>Irish Wheelchair Association</strong> needs your funding
            support, please make a donation and show your appreciation. As
            little or as much as you can afford, all donations go directly to
            the association.
          </P>
          <DonateButton />
        </FlexContainer>
      </Section>
      <Section>
        <FlexContainer>
          <H4 $theme={theme}>OUR THANKS</H4>
          <Small $theme={theme}>
            This event is made possible by the time and technology provided by
            our volunteers and the generosity of our sponsors
          </Small>
          <LogoContainer />
        </FlexContainer>
      </Section>
      <Section>
        <FlexContainer>
          <SocialPromo />
          <DonateButton />
        </FlexContainer>
      </Section>
      <Section>
        <Footer />
      </Section>
    </>
  )
}

export default IndexPage
